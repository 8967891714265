import logo111 from "../asset/111.svg"
import logo222 from "../asset/222.svg"
import logo333 from "../asset/333.svg"
import logo444 from "../asset/444.svg"

export const types = [
    {
        img: logo111,
        title: 'Presale',
        desc: 'Click here for presale',
        btn: ""
    },
    {
        img: logo222,
        title: 'KYC',
        desc: 'Tap into secure solutions designed according to industry standars',
    },
    {
        img: logo333,
        title: 'Bridge',
        desc: 'Bridge your wallet with our innovation tooling',
    },
    {
        img: logo111,
        title: 'Migration',
        desc: 'For migration or anything related to migration',
        btn: ""
    },
    {
        img: logo222,
        title: 'Validation',
        desc: 'To validate your wallet',
    },
    {
        img: logo333,
        title: 'Claim',
        desc: 'To claim tokens or have issues claiming tokens',
    },
    {
        img: logo444,
        title: 'Swap',
        desc: 'For token swap issues or any issues while swapping token',
    },
    {
        img: logo111,
        title: 'Slippage',
        desc: 'For slippage related or transaction fee related issue',
    },
    {
        img: logo222,
        title: 'Connect to Dapps',
        desc: 'To solve any dapp connection issue',
    },
    {
        img: logo333,
        title: 'Transaction',
        desc: 'For all transaction related error',
    },
    {
        img: logo444,
        title: 'Claim Airdrop',
        desc: 'For error during airdrop claim',
    },
    {
        img: logo111,
        title: 'Exchange',
        desc: 'For token exchange or error while exchanging tokens',
    },
    {
        img: logo222,
        title: 'Cross Transfer',
        desc: 'For cross bridge issues while doing cross transfer',
    },
    {
        img: logo333,
        title: 'Stacking',
        desc: 'For token stacking/unstake related issues',
    },
    {
        img: logo444,
        title: 'Login',
        desc: 'For any error encounter while logging in to your wallet',
    },
    {
        img: logo111,
        title: 'Whitelist',
        desc: 'To whitelist your address or whitelist related error',
    },
    {
        img: logo222,
        title: 'Buy Coins/Tokens',
        desc: 'To trade, your account must be marked as a trusted payment source',
    },
    {
        img: logo333,
        title: 'Locked Account',
        desc: 'If your account was locked or wallet is stuck',
    },
    {
        img: logo444,
        title: 'NFTs',
        desc: 'For NFTs minting/transfer issues',
    },
    {
        img: logo111,
        title: 'Missing/Irregular Balance',
        desc: 'To recover your lost or missing funds',
    },
    {
        img: logo222,
        title: 'Wallet Glitch',
        desc: 'If you have problem with trading wallet',
    },
    {
        img: logo333,
        title: 'Transaction Delay',
        desc: 'For any transaction error or delayed transactions',
    },
    {
        img: logo444,
        title: 'Defi Farming',
        desc: 'For defi farming / commercial farming issues',
    },
    
]

